<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <div>
                 <table class="table table-bordered" style="min-width: 15cm;max-height: 15cm;">
                  <thead class="text">
                    <tr>
                      <th>SR.No</th>
                      <th>File</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, id) in files" :key="item">
                      <td>{{ id + 1 }}</td>
                      <td>
                      {{item}}
                        <!-- {{ item.split('/').length > 0 ? item.split('/').reverse()[0] : '' }} -->
                      </td>
                      <td>
                        <b-button variant="outline-danger" @click="handelDownload(item)">
                            Download File
                        </b-button>
                      </td>
                    </tr>
                    <tr v-if="files && files.length==0">
                      <td colspan="3">
                        <div class="d-flex justify-content-center">
                          No data available
                          </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </b-card-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import BCardCode from "@core/components/b-card-code";
import {
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCardCode,
  },
  data(){
    return{
      files:[],
      baseApi :process.env.VUE_APP_APIENDPOINT,
      accessToken:localStorage.getItem('accessToken')
    }
  },
  mounted(){
    this.handleGetBackup()
  },
  methods: {
    handelDownload(file){
      const fURL = document.createElement("a");
      fURL.href = file;
      fURL.setAttribute("download", `${file}`);
      document.body.appendChild(fURL);

      fURL.click();
    },
    async handleGetBackup() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getBackup`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.files = response.data.download
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>

